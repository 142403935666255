<template>
<div class="registration">
  <div
      class="contact-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >Support Center</h2>
  </div>
  <div class="registration-block">
    <div class="contact-container">
      <h2 class="section-title">REGISTRATION</h2>
      <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M102.07 65V93.0008C102.07 95.5409 101.061 97.9769 99.2652 99.773C97.4691 101.569 95.033 102.578 92.493 102.578H37.507C34.967 102.578 32.5309 101.569 30.7348 99.773C28.9387 97.9769 27.9297 95.5409 27.9297 93.0008V36.9992C27.9297 34.4591 28.9387 32.0231 30.7348 30.227C32.5309 28.4309 34.967 27.4219 37.507 27.4219H65.5078" stroke="#3A3A3A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M104.051 19.2971L111.719 26.8025C111.729 26.9541 111.703 27.106 111.643 27.2455C111.583 27.3851 111.491 27.5083 111.373 27.6049L104.68 34.3283L79.2898 59.851L77.6648 61.5268L70.2305 68.9307L61.2523 69.7533L61.9531 60.9986L68.9102 54.0619L71.043 51.9291L96.5352 26.4064L103.228 19.683C103.423 19.4693 103.692 19.3385 103.98 19.3174H104.051V19.2971ZM103.98 16.2705C102.883 16.2893 101.838 16.7422 101.075 17.5299L94.382 24.2533L68.9203 49.7658L59.0383 59.6072L57.8906 73.1252L71.5813 71.8658L81.4633 62.0244L106.854 36.5119L113.547 29.7885C114.262 29.1585 114.7 28.2715 114.764 27.3205C114.829 26.3695 114.516 25.4314 113.892 24.7104L106.204 17.1541C105.612 16.5869 104.82 16.2766 104 16.2908L103.98 16.2705Z" fill="#3A3A3A"/>
        <path d="M93.9453 25.8984L105.117 37.0703" stroke="#3A3A3A" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M68.5547 51.2891L79.7266 62.4609" stroke="#3A3A3A" stroke-width="3" stroke-miterlimit="10"/>
      </svg>
      <div class="contact-info">
        <a href="#" class="nav-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"/>
          </svg>
          Info@cemglobal.com
        </a>
        <a href="#" class="nav-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
          </svg>
          (866) 915-1212
        </a>
      </div>
      <p class="text-item">
        If you have any questions or concerns, please don’t hesitate to contact us. Our amazing customer support will do
        their absolute best to ensure your problems are resolved as fast as possible. We are available to contact at any
        point 24/7.
      </p>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "Registration",
  components: {Footer},
  data(){
    return{
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/support-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.registration{
  background-color: $white;
  .contact-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
  .registration-block{
    .contact-container{
      padding: 40px 0;
      text-align: center;
      .contact-info{
        width: fit-content;
        margin: 0 auto;
        a{
          display: flex;
          color: $homeTextBlack;
          text-align: start;
          margin-top: 8px;
          &:nth-child(1){
            margin-top: 0;
          }
          svg{
            width: 15px;
            fill: $homeTextBlack;
            margin-right: 7px;
          }
        }
      }
      .text-item{
        margin-top: 20px;
      }
    }
  }
}
</style>